const MonthTranslationEnum: { [key: string]: string; } = {
	'Januar' : 'Jan',
	'Februar' : 'Feb',
	'März' : 'Mar',
	'April' : 'Apr',
	'Mai' : 'May',
	'Juni' : 'Jun',
	'Juli' : 'Jul',
	'August' : 'Aug',
	'September' : 'Sep',
	'Oktober' : 'Oct',
	'November' : 'Nov',
	'Dezember' : 'Dec'
}

const MonthGerToNumber: {[key: string]: string} = {
	"Januar" : "01",
	"Februar" : "02",
	"März" : "03",
	"April" : "04",
	"Mai" : "05",
	"Juni" : "06",
	"Juli" : "07",
	"August" : "08",
	"September" : "09",
	"Oktober" : "10",
	"November" : "11",
	"Dezember" : "12",
}

export { MonthTranslationEnum, MonthGerToNumber}