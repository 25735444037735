import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CourseCardComponent from "../course-card/course-card.component";
import { useFileProcessor } from "../../../../services/fileService/readFile";
import { useEffect, useState } from "react";
import { styled } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import styles from "./course-list.component.module.scss"
import { CourseServices } from "../../../../services/CourseServices";

interface DrillDownProps {
  increaseDrillDownIndex: (courseName: string) => void;
}

const CourseListComponent = ({increaseDrillDownIndex}: DrillDownProps) => {
  let courseService = new CourseServices();

  const [courses, setCourses] = useState<string[]>([]);
  const [targetCourses, setTargetCourses] = useState<string[]>([]);
  const [filterExpression, setFilterExpression] = useState<string>("");

  useEffect(() => {
    const load = () => {
      const tempCourses = courseService.getCourses();
      setCourses(tempCourses);
      setTargetCourses(tempCourses)
    };

    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const filterExpressionChange = (e: any) => {
    const expression = e.target.value;
    setFilterExpression(e.target.value);
    if (filterExpression !== "") {
      const targets = courses.filter(course => course.toLowerCase().includes(expression.toLowerCase()));
      setTargetCourses(targets);
    } else {
      setTargetCourses(courses);
    }
  }
  
  return (
    <>
        <div style={{ marginBottom: 32 }}>
          <Typography className={styles.searchText}>Suche nach einem Kurs</Typography>
          <TextField className={styles.textFieldset} value={filterExpression} onChange={filterExpressionChange} placeholder="zb. Aqua Jogging Spezial" 
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
          }}
          />
        </div>
        <div style={{ height: "100%" }}></div>
        <div style={{ height: "100%", marginBottom: 32 }}>
          <Typography style={{textAlign: "start", marginBottom: 15}}>Gefundene Kurse: {targetCourses.length}</Typography>
          <Grid container>
            {targetCourses.map((courseName, index) => {
              return (
                <Grid item xs={4}>
                  <CourseCardComponent courseName={courseName} increaseDrillDownIndex={() => {increaseDrillDownIndex(courseName)}} />
                </Grid>
              )
            })}
          </Grid>
        </div>
    </>
  )
}

export default CourseListComponent;