import { Navigate, Outlet } from 'react-router-dom';
import { AuthServices } from '../../services/AuthServices';
const ProtectedRoute = () => {
	const authServices = new AuthServices;
	
	const auth = authServices.authenticate();

	return auth ? <Outlet /> : <Navigate to="/signIn" />;
};

export default ProtectedRoute;
