import { ISessionStorageService, SessionStorageService } from "./common/SessionstorageService";

export interface IAuthServices {
	signIn: (user: string, pwd: string) => boolean;
	signOut: () => void;
	authenticate: () => boolean;
}

class AuthServices implements IAuthServices {
	sessionStorageService: ISessionStorageService;
	tempuser: string;
	temppwd: string;

	constructor() {
		this.sessionStorageService = new SessionStorageService;
		this.tempuser = 'admin';
		this.temppwd = 'admin#2022';

	}

	public signIn = (user: string, pwd: string): boolean => {
		if (user === this.tempuser && pwd === this.temppwd) {
			this.sessionStorageService.storeOrReplaceData("auth", true);
			return true;
		}

		return false;
	}

	public signOut = () => {
		this.sessionStorageService.clearSessionStorage();
	};

	public authenticate = (): boolean => {
		return this.sessionStorageService.getData("auth")
	};

}


export { AuthServices };