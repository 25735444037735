import { useFileProcessor } from '../../../../services/fileService/readFile';
import { useCSVDownloader } from 'react-papaparse';
import { useEffect, useState, FC } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid'
import 'gridjs/dist/theme/mermaid.css';
import "../../../../commonStyles/buttons.scss"
import { DataEnum } from '../../../../enum/dataEnum';
import OverviewTableSummaryComponent from './overview-table-summary-component';
import OverviewSelectedWeekComponent from './overview-selected-week-component';
import { CourseServices } from '../../../../services/CourseServices';
import { DownloadServices, IDownloadServices } from '../../../../services/DownloadService';
import Button from "@mui/material/Button"
import DownloadIcon from '@mui/icons-material/Download';


interface CourseWeeklyOverviewComponent {
  courseType: string;
}

const CourseWeeklyOverviewComponent = ({ courseType }: CourseWeeklyOverviewComponent) => {

  const courseServices = new CourseServices;

  const downloadServices: IDownloadServices = new DownloadServices();

  const [selectedWeek, setSelectedWeek] = useState("");
  const [courses, setCourses] = useState<{ [key: string]: any[] }>();

  useEffect(() => {
    const load = async () => {
      const tempCourses = courseServices.getWeeklyOverviewByCourse(courseType);
      setCourses(tempCourses);
    };

    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadExcel = () => {
    downloadServices.DataByCourse(courseType);
  }


  //todo maybe breadcrumbs to own component

  if (courses)
    return (
      <div>
        <div style={{ marginBottom: 32 }}>
          <OverviewTableSummaryComponent coursesData={courses} courseName={courseType} />
          <Button onClick={downloadExcel} variant="outlined" startIcon={<DownloadIcon />} style={{ marginBottom: 42, float: 'left', marginTop: 16}}>
            Kurs Speichern
          </Button>
        </div>
        <Grid container>
          <Grid item xs={2}>
            {Object.keys(courses).map((element, index) => {
              let trainersAmount: any = {};
              courses[element].forEach(x => {
                if (Object.keys(trainersAmount).includes(x[DataEnum.trainer])) {
                  trainersAmount[x[DataEnum.trainer]] = trainersAmount[x[DataEnum.trainer]] + 1;
                } else {
                  trainersAmount[x[DataEnum.trainer]] = 1;
                }
              })
              return (
                <div onClick={() => { setSelectedWeek(element) }} style={{ cursor: "pointer", border: "1px solid black", borderRadius: 8, width: "100%", height: 50, margin: "15px 0", display: "flex", alignItems: "center", backgroundColor: selectedWeek === element ? "#0b3a8a" : "" }}>
                  <Typography style={{ marginLeft: 15, color: selectedWeek === element ? "white" : "black" }}>{element === "undefined" ? "Kein Check In gefunden" : "KW " + element}</Typography>
                </div>
              )
            })}
          </Grid>
          <Grid item xs={1} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div style={{ height: "100%", width: 1, borderRight: "1px solid black" }}></div>
          </Grid>
          <Grid item xs={9}>
            {selectedWeek !== "" ? <OverviewSelectedWeekComponent data={courses[selectedWeek]} courseName={courseType} selectedWeek={selectedWeek} /> : <h1>Bitte woche wählen</h1>}
          </Grid>
        </Grid>
      </div>
    );

  return <>Loading...</>
};

export default CourseWeeklyOverviewComponent;
