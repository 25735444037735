export enum DataEnum {
  checkIns = 'Check-ins',
  name = 'Name',
  checkInsTimeStamp = "Check-in Zeitstempel",
  trainer = "Trainer",
  ticketType = "Ticket Type",
  timeStamp = "TimeStamp",
  paymentDate = "Payment Date",
  lastName = "Last Name",
  attendeeEmail = "Attendee E-mail",
  paymentdate = "Payment Date",
  orderNumber = "Order Number",
  paymentGateway = "Payment Gateway",
  orderStatus = "Order Status",
  orderTotal = "Order Total",
  orderTotalShownOnce = "Order Total (Shown Once)",
  ticketSubtotal = "Ticket Subtotal",
  ticketDiscount = "Ticket Discount",
  ticketTax = "Ticket Tax",
  ticketTotal = "Ticket Total",
  ticketCode = "Ticket Code",
  ticketID = "Ticket ID",
  buyerFirstName = "Buyer First Name",
  buyerLastName = "Buyer Last Name",
  buyerName = "Buyer Name",
  buyerEMail = "Buyer E-Mail",
  checkedIn = "Checked-in",
  checkOuts = "Check-outs",
  aPIKey = "API Key",
  price = "Price",
  companyName = "Company Name",
  addressLine1 = "Address Line 1",
  addressLine2 = "Address Line 2",
  stadt = "Stadt",
  state = "State",
  postcode = "Postcode",
  land = "Land",
  phone = "Phone",
  couponCode = "Coupon Code",
  geburtsdatum = "GeÂ­burtsÂ­daÂ­tum",
  telefonnummer = "Telefonnummer (SMS)",
  einwilligungZurDatenverarbeitung = "Einwilligung zur Datenverarbeitung",
  kurs2 = "Kurs 2:",
  kurs1 = "Kurs 1:",
  weekNumber = "weekNumber"
}
