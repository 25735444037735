import Papa from "papaparse";
import { DataEnum } from "../enum/dataEnum";
// import { ExportToCsv } from 'export-to-csv';
import { FileManagerServices, IFileMangerServices } from "./FileManagerService";


export interface IDownloadServices {
    DataByCourse: (course: string) => void;
    DataByCourseAndWeek: (course: string, week: string) => void;
}

class DownloadServices implements IDownloadServices {

    fileMangerServices: IFileMangerServices
    parseConfig: any;
    constructor() {
        this.fileMangerServices = new FileManagerServices();
        this.parseConfig = {
            delimiter: ";",
        }
    }

    public DataByCourse = (course: string) => {
        let targetData: any[] = this.fileMangerServices.getProcessedData().filter((data: any) => data[DataEnum.ticketType] === course);
        targetData.forEach(element => {
            const dateTime = new Date(element[DataEnum.checkInsTimeStamp])
			const minutesString = dateTime.getMinutes().toString().length === 1 ? "0" + dateTime.getMinutes().toString() : dateTime.getMinutes().toString();  
			const hourString = dateTime.getHours().toString().length === 1 ? "0" + dateTime.getHours().toString() : dateTime.getHours().toString();  

			let dateString = dateTime.getDate() + "." + (dateTime.getMonth() + 1) + "." + dateTime.getFullYear() + " " + hourString + ":" + minutesString;

            element[DataEnum.checkInsTimeStamp] = dateString;
            delete element[DataEnum.checkIns]
    })
        const csv = Papa.unparse(targetData, this.parseConfig);
        this.download(csv, course);
    }

    public DataByCourseAndWeek = (course: string, week: string) => {
        let courseData: any[] = this.fileMangerServices.getProcessedData().filter((data: any) => data[DataEnum.ticketType] === course && data[DataEnum.weekNumber]?.toString() === week);
        courseData.forEach(element => {
            const dateTime = new Date(element[DataEnum.checkInsTimeStamp])
			const minutesString = dateTime.getMinutes().toString().length === 1 ? "0" + dateTime.getMinutes().toString() : dateTime.getMinutes().toString();  
			const hourString = dateTime.getHours().toString().length === 1 ? "0" + dateTime.getHours().toString() : dateTime.getHours().toString();  

			let dateString = dateTime.getDate() + "." + (dateTime.getMonth() + 1) + "." + dateTime.getFullYear() + " " + hourString + ":" + minutesString;

            element[DataEnum.checkInsTimeStamp] = dateString;
            delete element[DataEnum.checkIns]
        })

        const csv = Papa.unparse(courseData, this.parseConfig);
        const fileName = course + "_KW" + week;
        this.download(csv, fileName);
    }


    private download = (csv: any, fileName: string) => {
        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = "Downloads";
        csvURL = window.URL.createObjectURL(csvData);

        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute("download", fileName + '.csv');
        tempLink.click();
    }

}

export { DownloadServices };