import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DoneIcon from '@mui/icons-material/Done';
import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import styles from "./upload.component.module.scss"
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { FileManagerServices, IFileMangerServices } from "../../services/FileManagerService";

enum SnackbarTypes {
    Success = 'success',
    Error = 'error',
}


const UploadComponent = () => {

    const fileManagerService: IFileMangerServices = new FileManagerServices;

    const navigate = useNavigate();

    const fileTypes = ["CSV"];
    const [files, setFiles] = useState<any[]>([]);
    const [fileNames, setFileNames] = useState<string[]>([]);
    const [showRemoveIcon, setShowRemoveIcon] = useState<string>("");
    const [disableDropDown, setDisableDropDown] = useState<boolean>(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarText, setSnackbarText] = useState<string>("");
    const [snackbarType, setSnackbarType] = useState<SnackbarTypes>(SnackbarTypes.Success);

    useEffect(() => {
        const load = async () => {
            const files = fileManagerService.getUploadedFiles();
            if (files !== null) {
                setFileNames(files);
                setDisableDropDown(true)
            }
            else
                setFileNames([]);
        }

        load();
    }, [setFileNames]);

    const deleteFile = (fileName: string) => {
        const currFileNames = fileNames;
        const newFileNames = currFileNames.filter((element) => element !== fileName)
        setFileNames(newFileNames);
        fileManagerService.setUploadedFiles(newFileNames);
        setSnackbarType(SnackbarTypes.Success);
        setSnackbarText("Datei {xyz} erfolgreich gelöscht.");
        setOpenSnackbar(true);
        setShowRemoveIcon("");
    };

    const deleteAllFile = () => {
        setFileNames([]);
        fileManagerService.removeAllData();
        setSnackbarType(SnackbarTypes.Success);
        setSnackbarText("Dateien erfolgreich gelöscht.");
        setOpenSnackbar(true);
        setShowRemoveIcon("");
        setDisableDropDown(false)
    };

    const handleChange = (file: any) => {
        const targetFile = file[0];

        for (const element of file) {
            if (!files.includes(element.name)) setFiles((prevFiles) => [...prevFiles, element]);
            if (!fileNames.includes(element.name)) setFileNames((prevFileNames) => [...prevFileNames, element.name]);
        }
    };

    const handleClose = (event: any) => {
        setOpenSnackbar(false)
    };


    const handeFileUpload = async () => {
        const sucsessfullyUploaded = await fileManagerService.processAndStoreFiles(files)
        if (sucsessfullyUploaded[0]) {
            setDisableDropDown(true);
            setSnackbarType(SnackbarTypes.Success);
            setSnackbarText("Erfoglreich hochgeladen.");
            setOpenSnackbar(true);
        } else {
            setSnackbarType(SnackbarTypes.Error);
            const filesInString = sucsessfullyUploaded[1].join(", ")
            setSnackbarText("Ein fehler ist aufgetreten. Bitte überprüfen sie die Datei(en): " + filesInString + "!");
            setOpenSnackbar(true);
            setFileNames([]);
        }
    };



    //TODO if not uploaded lightgreen check mark
    return (
        <>
            <div className={styles.wrapper}>
                <FileUploader disabled={disableDropDown} classes={styles.fileUploader} label="CSV Dateien hineinziehen oder durchsuchen" multiple={true} handleChange={handleChange} name="file" types={fileTypes} />
                <div className={styles.selectedFilesWrapper}>
                    {fileNames.map((fileName: string) => {
                        return (
                            <div className={styles.selectedFile} onMouseEnter={() => { setShowRemoveIcon(fileName) }} onMouseLeave={() => { setShowRemoveIcon("") }}>
                                <img alt="csvIcon" src="common/icons/csv.png" height="45px" style={{ marginRight: 9 }} />
                                <Typography style={{ fontSize: 18 }}>{fileName}</Typography>
                                {/* {showRemoveIcon !== fileName ? */}
                                <DoneIcon fontSize="large" className={styles.iconRight} sx={{ color: "green" }} />
                                {/* : */}
                                {/* <ClearIcon fontSize="large" style={{ cursor: "pointer" }} className={styles.iconRight} onClick={() => deleteFile(fileName)} />} */}
                            </div>
                        );
                    })}
                </div>
                <div className={styles.buttonsWrapper}>
                    <Button className="cancleButton" onClick={deleteAllFile} disabled={fileNames.length <= 0} style={{ marginRight: 24 }} size="large" variant="outlined">Alle Dateien löschen</Button>
                    <Button className="continueButton" onClick={handeFileUpload} disabled={fileNames.length <= 0 || disableDropDown} size="large" variant="contained">Speichern</Button>
                </div>
            </div>

            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={openSnackbar} autoHideDuration={6000} onClose={handleClose}>
                <Alert variant="filled" onClose={handleClose} severity={snackbarType} sx={{ width: 500 }}>
                    {snackbarText}
                </Alert>
            </Snackbar>
        </>
    )
}

export default UploadComponent;