import { Paper, Typography } from '@mui/material';
import { Grid } from 'gridjs-react';
import 'gridjs/dist/theme/mermaid.css';
import { FC } from 'react';
import Button from "@mui/material/Button"
import DownloadIcon from '@mui/icons-material/Download';
import { DataEnum } from '../../../../enum/dataEnum';
import { DownloadServices, IDownloadServices } from '../../../../services/DownloadService';

const columns = [
  {
    data: (row: any) => row[DataEnum.name],
    name: "Name"
  },
  {
    data: (row: any) => row[DataEnum.trainer],
    name: "Trainer"
  },
  {
    data: (row: any) => {
      let date = new Date(row[DataEnum.timeStamp]);
      
      const minutesString = date.getMinutes().toString().length === 1 ? "0" + date.getMinutes().toString() : date.getMinutes().toString();  
			const hourString = date.getHours().toString().length === 1 ? "0" + date.getHours().toString() : date.getHours().toString();  

      let dateString = date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear() + " " + hourString + ":" + minutesString;

      return dateString.includes("NaN") ? "" : dateString;
    },
    name: "Check In"
  },
];

const OverviewSelectedWeekComponent: FC<{ data: any[], courseName: string, selectedWeek: string }> = ({ data, courseName, selectedWeek }) => {

  const downloadServices: IDownloadServices = new DownloadServices();

  let courseParticipants: any[] = [];
  let trainer: any[] = [];

  //TODO into useEffect
  data.sort((a, b) => {
    const dateA = new Date(a[DataEnum.timeStamp])
    const dateB = new Date(b[DataEnum.timeStamp])
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  })

  data.forEach(element => {
    if (!courseParticipants.includes(element[DataEnum.name])) {
      courseParticipants.push(element[DataEnum.name]);
    }
    if (!trainer.includes(element[DataEnum.trainer])) {
      trainer.push(element[DataEnum.trainer]);
    }
  });

  const download = () => {
    downloadServices.DataByCourseAndWeek(courseName, selectedWeek);
  }

  return (
    <div>
      <div style={{ marginBottom: 36, display: "flex" }}>
        <Paper elevation={7} style={{ width: "fit-content", padding: 16, borderRadius: 16, marginBottom: 8, marginRight: 16 }}>
          <img alt="trainerIcon" src="../Overview/icons/participants.png" style={{ height: 35, marginBottom: 8 }} />
          <Typography style={{ fontSize: 26 }}>{courseParticipants.length}</Typography>
          <Typography>Anzahl Kursteilnehmer in KW {selectedWeek}</Typography>
        </Paper>
        <Paper elevation={7} style={{ width: "fit-content", padding: 16, borderRadius: 16, marginBottom: 8 }}>
          <img alt="trainerIcon" src="../Overview/icons/trainer.png" style={{ height: 35, marginBottom: 8 }} />
          <Typography style={{ fontSize: 26 }}>{trainer.length}</Typography>
          <Typography>Anzahl Trainer in KW {selectedWeek}</Typography>
        </Paper>
      </div>
      <div style={{ float: "right" }}>
      <Button onClick={download} variant="outlined" startIcon={<DownloadIcon />} style={{marginBottom: 16}}>
        Speichern
      </Button>
      </div>
      <Grid
        data={data}
        columns={columns}
      />
    </div>
  )
};

export default OverviewSelectedWeekComponent;
