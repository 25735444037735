import { DataEnum } from "../enum/dataEnum";
import { HelperServices, IHelperServices } from "./common/HelperServices";
import { ISessionStorageService, SessionStorageService } from "./common/SessionstorageService";

class CourseServices {
	sessionStorageService: ISessionStorageService;
	helperServices: IHelperServices;
	constructor() {
		this.sessionStorageService = new SessionStorageService;
		this.helperServices = new HelperServices;
	}

	public getCourses = () => {
		let courses: string[] = this.sessionStorageService.getData('courses');
		if (courses === null) {
			const storedData: string[] = this.sessionStorageService.getData('csvData');
			if (storedData !== null) {
				courses = [];
				storedData.forEach((element: any) => {
					const course = element[DataEnum.ticketType];
					if (!courses.includes(course) && course != undefined && course != '') {
						courses.push(course);
					}
				});
				this.sessionStorageService.storeOrReplaceData('courses', courses);
			}
		}
		return courses === null ? [] as string[] : courses;
	};

	public getWeeklyOverviewByCourse = (course: string) => {
		const data: any[] = this.sessionStorageService.getData('csvData');
		const targetCourses = data.filter(element => element[DataEnum.ticketType] === course);
		const groupByWeek = this.helperServices.groupBy([DataEnum.weekNumber]);
		const coursesGroupedByWeek = groupByWeek(targetCourses);

		return coursesGroupedByWeek;
	}
}

export { CourseServices };