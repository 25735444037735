import Papa from "papaparse";
import { DataEnum } from "../enum/dataEnum";
import { MonthTranslationEnum } from "../enum/monthTranslationEnum";
import { IRawData } from "../models/IRawData";
import { HelperServices, IHelperServices } from "./common/HelperServices";
import { ISessionStorageService, SessionStorageService } from "./common/SessionstorageService";


export interface IFileMangerServices {
    processAndStoreFiles: (files: any) => Promise<[boolean, string[]]>;
    getUploadedFiles: () => string[];
    setUploadedFiles: (fileNames: string[]) => void;
    getProcessedData: () => any;
    removeAllData: () => void;
}

class FileManagerServices implements IFileMangerServices {

    importantFields: string[];
    sessionStorageService: ISessionStorageService;
    helperServices: IHelperServices;
    constructor() {
        this.sessionStorageService = new SessionStorageService;
        this.helperServices = new HelperServices;

        //TODO make shorter
        this.importantFields = [
            "First Name",
            "Last Name",
            "Name",
            "Attendee E-mail",
            "Order Number",
            "Ticket ID",
            "Ticket Type",
            "Checked-in",
            "Check-ins",
            "Check-outs",
        ]
    }


    //TODO refactor
    //https://bobbyhadz.com/blog/javascript-change-value-of-object-in-array#:~:text=To%20change%20the%20value%20of,will%20get%20updated%20in%20place.
    public processAndStoreFiles = async (files: any): Promise<[boolean, string[]]> => {
        let processedData: IRawData[] = [];
        let rawData: IRawData[] = [];
        let fileNames: string[] = [];

        for await (const file of files) {
            try {
                fileNames.push(file.name);
                const res: any = await this.parseFile(file);
                rawData.push(...res);
            } catch (error: any) {
                return [false, fileNames];
            }
        }

        // sessionStorage.setItem('uploadedFiles', JSON.stringify(fileNames));
        try {
            rawData.forEach((element: any) => {
                if (element) {
                    if (element[DataEnum.checkIns] && element[DataEnum.checkIns] !== '') {
                        let checkIns = element[DataEnum.checkIns].split('\n');
                        checkIns.forEach((checkIn: string) => {
                            if (checkIn !== "") {
                                let checkInSplit = checkIn.split('(');

                                const dateTime = checkInSplit[0].slice(0, -1);
                                const dateTimeSplit = dateTime.split('-');
                                const month = dateTimeSplit[0].split(' ')[1];
                                const date = dateTimeSplit[0].replace(month, MonthTranslationEnum[month]);
                                const timeStamp = new Date(date + ' ' + dateTimeSplit[1]);
                                const week = this.helperServices.getWeekNumber(timeStamp);

                                let trainer = checkInSplit[1].slice(0, -1);
                                if (trainer.includes(')')) {
                                    trainer = trainer.split(')')[0];
                                }

                                if (element[DataEnum.ticketType]?.includes("&#038;")) {
                                    const splitString = element[DataEnum.ticketType].split("&#038;");
                                    const newString = splitString[0] + "&" + splitString[1];
                                    element[DataEnum.ticketType] = newString;
                                }


                                const newObject = Object.assign({}, element, {
                                    [DataEnum.checkInsTimeStamp]: timeStamp,
                                    [DataEnum.trainer]: trainer,
                                    [DataEnum.timeStamp]: timeStamp,
                                    [DataEnum.weekNumber]: week
                                });

                                processedData.push(newObject);
                            }
                        });
                    } else {
                        if (element[DataEnum.ticketType]?.includes("&#038;")) {
                            const splitString = element[DataEnum.ticketType].split("&#038;");
                            const newString = splitString[0] + "&" + splitString[1];
                            element[DataEnum.ticketType] = newString;
                        }
                        delete element[DataEnum.checkIns];
                        element[DataEnum.checkInsTimeStamp] = '';
                        element[DataEnum.trainer] = '';
                        element[DataEnum.timeStamp] = '';
                        processedData.push(element);
                    }
                }
            });

            this.sessionStorageService.storeOrReplaceData("uploadedFiles", fileNames);
            this.sessionStorageService.storeOrReplaceData("csvData", processedData);
            return [true, fileNames];
        } catch (error) {
            return [false, fileNames];
        }
    };

    public getUploadedFiles = (): string[] => {
        return this.sessionStorageService.getData("uploadedFiles");
    }

    public setUploadedFiles = (fileNames: string[]) => {
        this.sessionStorageService.storeOrReplaceData('uploadedFiles', fileNames);
    }

    public getProcessedData = () => {
        return this.sessionStorageService.getData("csvData");
    };

    public removeAllData = () => {
        this.sessionStorageService.getAllKeys().forEach(key => {
            if (key !== "auth") {
                this.sessionStorageService.removeWithStorageName(key);
            }
        })
    }

    private parseFile = async (file: any) => {
        let allKeyPresent: boolean = false;
        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                header: true,
                complete: (results) => {
                    this.importantFields.forEach((field: string) => {
                        if (!results.meta.fields?.includes(field)) {
                            reject(new Error(field + " is not present in file"))
                        }
                    })
                    resolve(results.data);
                }
            });
        });
    };
}

export { FileManagerServices };