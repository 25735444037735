import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import ProtectedRoute from './components/auth/ProtectedRoute';
import UploadProtectedRoute from './components/uploadProtection/UploadProtectedRoute';
// import HomePage from './pages/Home/HomePage';
import HomePage from './pages/home-page/home-page';
// import LoginPage from './pages/Login/Loginpage';
import UploadPage from './pages/Upload/UploadPage';
import NewUploadPage from './pages/upload-page/upload-page';
import SideBarComponent from './components/common/side-bar/side-bar.component';
import TablePage from './pages/table-page/table-page';
import LoginPage from './pages/login-page/login-page';

//TODO 2 => rename new... and remove old stuff
export default function App() {
	return (
		<Router>
			<Routes>
				<Route element={<ProtectedRoute />}>
					<Route element={<SideBarComponent />}>
						<Route path="/home" element={<HomePage />} />
						<Route path="/table" element={<TablePage />} />
						<Route path="/upload" element={<NewUploadPage />} />
					</Route>
				</Route>
				<Route path="/signIn" element={<LoginPage />} />
				<Route path="/" element={<Navigate to="/home" replace={true} />} />
				<Route path="/*" element={<Navigate to="/home" replace={true} />}/>
			</Routes>
		</Router>
	);
}