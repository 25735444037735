import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import styles from "./course-card.component.module.scss"
import styled from "@emotion/styled";
import { IDownloadServices, DownloadServices } from "../../../../services/DownloadService";

interface CourseNameInterface {
  courseName: string,
  increaseDrillDownIndex: () => void
}

const CourseCardComponent = ({ courseName, increaseDrillDownIndex }: CourseNameInterface) => {
  
  const downloadServices: IDownloadServices = new DownloadServices();
  
  //TOOD public css
  const WhiteButton = styled(Button)(() => ({
    color: "#0b3a8a",
    backgroundColor: "white",
    '&:hover': {
      backgroundColor: "white",
    },
  }));

  const WhiteOutlinedButton = styled(Button)(() => ({
    color: "white",
    borderColor: "white",
    '&:hover': {
      borderColor: "white",
    },
  }));

  const downloadExcel = () => {
    downloadServices.DataByCourse(courseName);
  }
  
  
  return (
    <>
      <Paper elevation={10} className={styles.paper}>
        <div style={{ height: 80, width: 80, borderRadius: "50%", marginBottom: 24, display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(27, 179, 235, 0.3)" }}>
          <img alt="defaultAquafitImage" src="Logo-AquaFit-vec.svg" />
        </div>
        {/* <img style={{ height: "50px" }} alt={calculator.imageAltTag} src={calculator.imagePath} /> */}
        <Typography className={styles.title}>{courseName}</Typography>
        <div className={styles.hoverMenu}>
          <Typography className={styles.title} style={{color: "white", width: "80%", margin: "24px auto 0 auto"}}>{courseName}</Typography>
          <Grid container className={styles.hoverButtons}>
            <Grid item xs={6} className={styles.centerItem}>
              <WhiteOutlinedButton onClick={downloadExcel} size="medium" variant="outlined">
                Speichern
              </WhiteOutlinedButton>
            </Grid>
            <Grid item xs={6} className={styles.centerItem}>
              <WhiteButton onClick={increaseDrillDownIndex} size="medium" variant="contained">
                Mehr anzeigen
              </WhiteButton>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </>
  )
}

export default CourseCardComponent;