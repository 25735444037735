import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from 'gridjs-react';
import 'gridjs/dist/theme/mermaid.css';
import { FC } from 'react';
import "../../../../commonStyles/buttons.scss"
import { useCSVDownloader } from 'react-papaparse';
import { DataEnum } from '../../../../enum/dataEnum';
import { Paper, Typography } from '@mui/material';

const columns = [
  {
    data: (row: any) => row.name,
    name: "Trainer"
  },
  {
    data: (row: any) => row.amount,
    name: "Einheiten abgehalten"
  },
  {
    data: (row: any) => row.propotion,
    name: "Umsatz"
  }
];

const OverviewTableSummaryComponent: FC<{ coursesData: { [key: string]: any[] }, courseName: string }> = ({ coursesData, courseName }) => {

  //Evtl anzahl der teilnehmer
  const { CSVDownloader, Type } = useCSVDownloader();
  let gridData: any[] = [];
  let persons: string[] = [];
  let sumOfTicketPrices: number = 0;
  let sumOfCourses = 0;

  Object.keys(coursesData).forEach((element, index) => {
    coursesData[element].forEach(x => {
      let targetIndex = gridData.findIndex(data => data.name === x[DataEnum.trainer]);
      let targetPersonIndex = persons.findIndex((byerName) => byerName === x[DataEnum.buyerName]);
      if (x[DataEnum.trainer] !== "") {
        if (targetIndex < 0) {
          gridData.push({
            name: x[DataEnum.trainer],
            amount: 1
          })
        } else {
            gridData[targetIndex].amount = gridData[targetIndex].amount + 1;
        }
      }

      if (x[DataEnum.buyerName] !== "") {
        if (targetPersonIndex < 0) {
          persons.push(x[DataEnum.buyerName])
          const priceInFloat = parseFloat(x[DataEnum.orderTotal]);
          sumOfTicketPrices += priceInFloat
        }
      }
    })
  })

  gridData.forEach((data, index) => {
    sumOfCourses += data.amount;
  })

  gridData.forEach((data, index) => {
    gridData[index].propotion = ((sumOfTicketPrices / sumOfCourses) * data.amount).toFixed(2);
  })

  gridData.push({
    name: "Summe",
    amount: sumOfCourses,
    propotion: sumOfTicketPrices
  })

  return (
    <div style={{ width: 700, marginTop: 32, textAlign: "start" }}>
      <div style={{marginBottom: 26}}>
      <div style={{ marginBottom: 36, display: "flex" }}>
        <Paper elevation={7} style={{ width: "fit-content", textAlign: "center", padding: 16, borderRadius: 16, marginBottom: 8, marginRight: 16 }}>
          <img alt="trainerIcon" src="../Overview/icons/ticket.png" style={{ height: 35, marginBottom: 8 }} />
          <Typography style={{ fontSize: 26 }}>{persons.length}</Typography>
          <Typography>Insgesammt Verkaufte Tickets</Typography>
        </Paper>
        <Paper elevation={7} style={{ width: "fit-content", textAlign: "center", padding: 16, borderRadius: 16, marginBottom: 8, marginRight: 16 }}>
          <img alt="trainerIcon" src="../Overview/icons/average.png" style={{ height: 35, marginBottom: 8 }} />
          <Typography style={{ fontSize: 26 }}>{(sumOfTicketPrices / persons.length).toFixed(2)}€</Typography>
          <Typography>Durchschnittlicher Ticketpreis</Typography>
        </Paper>
        <Paper elevation={7} style={{ width: "fit-content", textAlign: "center", padding: 16, borderRadius: 16, marginBottom: 8 }}>
          <img alt="trainerIcon" src="../Overview/icons/money-bag.png" style={{ height: 35, marginBottom: 8 }} />
          <Typography style={{ fontSize: 26 }}>{(sumOfTicketPrices / sumOfCourses).toFixed(2)}€</Typography>
          <Typography>Umsatz pro abgehaltene Einheit</Typography>
        </Paper>
      </div>
      </div>
      <Grid
        data={gridData}
        columns={columns}
      />
    </div>
  );
};

export default OverviewTableSummaryComponent;
