import { Button, Grid, Paper, TextField } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthServices } from "../../services/AuthServices";
import styles from "./login.component.module.scss"

const LoginComponent = () => {
  const authServices = new AuthServices;
  const navigate = useNavigate();

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [incorrectCredentials, setIncorrectCredentials] = useState<boolean>(false);

  const handleUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUser(event.target.value);
    setIncorrectCredentials(false)
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPwd(event.target.value);
    setIncorrectCredentials(false)
  };


  const loginClick = () => {
    const result = authServices.signIn(user, pwd);
    if (result) {
      navigate('/home', { replace: true });
    } else {
      setUser("");
      setPwd("");
      setIncorrectCredentials(true)
    }
  }

  return (
    <div className={styles.outerWrapper}>
      <Paper elevation={12} className={styles.loginPaper}>
        <Grid container style={{ height: "100%", width: "100%" }}>
          <Grid item xs={4} className={styles.centerChildren} style={{ height: "100%", width: "100%" }}>
            <div className={styles.imageContainer}></div>
          </Grid>
          <Grid item xs={8}>
            <div className={styles.innerLoginWrapper}>
              <img width="50%" src="/Logo-AquaFit-vec.svg" alt="aquafit logo" />
              <div className={styles.inputFields}>
                <TextField
                  fullWidth
                  error={incorrectCredentials}
                  style={{marginBottom: 15}}
                  label="Username"
                  value={user}
                  onChange={handleUserChange}
                />
                <TextField
                  style={{marginBottom: 15}}
                  fullWidth
                  error={incorrectCredentials}
                  label="Passwort"
                  value={pwd}
                  type="password"
                  onChange={handlePasswordChange}
                />
                <button className="classicButton" style={{width: "100%"}} onClick={loginClick}>Login!</button>
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
};

export default LoginComponent;
