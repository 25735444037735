

export interface IHelperServices {
	groupBy: (key: any) => (array: any[]) => any;
	getWeekNumber: (date: Date) => number;
}

class HelperServices implements IHelperServices {
	public groupBy = (key: any) => (array: any[]) =>
		array.reduce((objectsByKeyValue, obj) => {
			const value = obj[key];
			objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
			return objectsByKeyValue;
		}, {});

	public getWeekNumber = (date: Date): number => {
		let currentDate: Date = new Date(date);
		let startDate: Date = new Date(currentDate.getFullYear(), 0, 1);
		var days: number = Math.floor((currentDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000));
		var weekNumber: number = Math.ceil(days / 7);

		return weekNumber;
	}

}

export { HelperServices };