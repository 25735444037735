import { DataEnum } from '../../enum/dataEnum';
import { useEffect, useState } from 'react';
import { useFileProcessor } from '../../services/fileService/readFile';
import { DataGrid, deDE, GridComparatorFn, GridToolbar, GridValueFormatterParams } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { IFileMangerServices, FileManagerServices } from '../../services/FileManagerService';
import { MonthGerToNumber } from '../../enum/monthTranslationEnum';

const checkInTimeStampComparator: GridComparatorFn<String> = (a_date_input, b_date_input) => {
	const a_date_split = a_date_input.split("-");
	const b_date_split = b_date_input.split("-");


	const a_date = new Date(a_date_split[0] + a_date_split[1]);
	const b_date = new Date(b_date_split[0] + b_date_split[1]);
	return a_date.getTime() - b_date.getTime();
}

const columns = [
	{
		field: DataEnum.name,
		headerName: DataEnum.name,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.checkInsTimeStamp,
		headerName: DataEnum.checkInsTimeStamp,
		editable: true,
		width: 200,
		valueFormatter: (params: GridValueFormatterParams<Date>) => {
			if (params.value == null) {
				return '';
			}

			const dateTime = new Date(params.value)
			const minutesString = dateTime.getMinutes().toString().length === 1 ? "0" + dateTime.getMinutes().toString() : dateTime.getMinutes().toString();  
			const hourString = dateTime.getHours().toString().length === 1 ? "0" + dateTime.getHours().toString() : dateTime.getHours().toString();  

			let dateString = dateTime.getDate() + "." + (dateTime.getMonth() + 1) + "." + dateTime.getFullYear() + " " + hourString + ":" + minutesString;

			if (dateString.toLowerCase().includes("nan")) {
				return "Kein check in gefunden"
			} else {
				return dateString;
			}
		},

	},
	{
		field: DataEnum.trainer,
		headerName: DataEnum.trainer,
		editable: true,
		width: 150
	},
	{
		field: DataEnum.ticketType,
		headerName: DataEnum.ticketType,
		editable: true,
		width: 350
	},
	{
		field: DataEnum.paymentDate,
		headerName: DataEnum.paymentDate,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.lastName,
		headerName: DataEnum.lastName,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.attendeeEmail,
		headerName: DataEnum.attendeeEmail,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.orderNumber,
		headerName: DataEnum.orderNumber,
		editable: true,
		width: 120
	},
	{
		field: DataEnum.paymentGateway,
		headerName: DataEnum.paymentGateway,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.orderStatus,
		headerName: DataEnum.orderStatus,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.orderTotal,
		headerName: DataEnum.orderTotal,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.orderTotalShownOnce,
		headerName: DataEnum.orderTotalShownOnce,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.ticketSubtotal,
		headerName: DataEnum.ticketSubtotal,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.ticketDiscount,
		headerName: DataEnum.ticketDiscount,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.ticketTax,
		headerName: DataEnum.ticketTax,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.ticketTotal,
		headerName: DataEnum.ticketTotal,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.ticketCode,
		headerName: DataEnum.ticketCode,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.ticketID,
		headerName: DataEnum.ticketID,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.buyerFirstName,
		headerName: DataEnum.buyerFirstName,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.buyerLastName,
		headerName: DataEnum.buyerLastName,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.buyerName,
		headerName: DataEnum.buyerName,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.buyerEMail,
		headerName: DataEnum.buyerEMail,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.checkedIn,
		headerName: DataEnum.checkedIn,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.checkOuts,
		headerName: DataEnum.checkOuts,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.aPIKey,
		headerName: DataEnum.aPIKey,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.price,
		headerName: DataEnum.price,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.companyName,
		headerName: DataEnum.companyName,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.addressLine1,
		headerName: DataEnum.addressLine1,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.addressLine2,
		headerName: DataEnum.addressLine2,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.stadt,
		headerName: DataEnum.stadt,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.state,
		headerName: DataEnum.state,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.postcode,
		headerName: DataEnum.postcode,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.land,
		headerName: DataEnum.land,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.phone,
		headerName: DataEnum.phone,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.couponCode,
		headerName: DataEnum.couponCode,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.geburtsdatum,
		headerName: DataEnum.geburtsdatum,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.telefonnummer,
		headerName: DataEnum.telefonnummer,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.einwilligungZurDatenverarbeitung,
		headerName: DataEnum.einwilligungZurDatenverarbeitung,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.kurs2,
		headerName: DataEnum.kurs2,
		editable: true,
		width: 200
	},
	{
		field: DataEnum.kurs1,
		headerName: DataEnum.kurs1,
		editable: true,
		width: 200
	}
];

const TableComponent = () => {
	const fileManagerServices: IFileMangerServices = new FileManagerServices;

	const [processedData, setprocessedData] = useState<any[]>();
	const [pageSize, setPageSize] = useState<number>(30);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		const load = () => {
			setLoading(true)
			const tempData = fileManagerServices.getProcessedData();
			setprocessedData(tempData);
			setLoading(false);
		};

		load();
	}, []);

	if (!loading) {
		if (processedData) {
			return (
				<div style={{ height: '95%' }}>
					<Box sx={{ height: '100%', width: '100%' }}>
						<DataGrid
							components={{ Toolbar: GridToolbar }}
							componentsProps={{ toolbar: { csvOptions: { delimiter: ";" } } }}
							localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
							// @ts-ignore
							rows={processedData}
							columns={columns}
							pageSize={pageSize}
							onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
							rowsPerPageOptions={[20, 30, 50, 100]}
							pagination
							getRowId={() => Math.random() * (999999 - 0) + 0}
							checkboxSelection
							disableSelectionOnClick
							experimentalFeatures={{ newEditingApi: true }}
							initialState={{
								columns: {
									columnVisibilityModel: {
										[DataEnum.buyerEMail]: false,
										[DataEnum.buyerFirstName]: false,
										[DataEnum.buyerLastName]: false,
										[DataEnum.buyerName]: false,
										[DataEnum.einwilligungZurDatenverarbeitung]: false,
										[DataEnum.aPIKey]: false,
										[DataEnum.orderTotalShownOnce]: false,
										[DataEnum.ticketTax]: false,
										[DataEnum.ticketTotal]: false,
										[DataEnum.ticketCode]: false,
										[DataEnum.ticketID]: false,
										[DataEnum.checkOuts]: false,
										[DataEnum.price]: false,
										[DataEnum.state]: false,
										[DataEnum.land]: false,
										[DataEnum.kurs1]: false,
										[DataEnum.kurs2]: false,
										[DataEnum.geburtsdatum]: false,
										[DataEnum.telefonnummer]: false
									}
								}
							}}
						/>
					</Box>
				</div>
			);
		}
		else {
			return <div>keine daten Gefunden!</div>
		}
	} else {
		return <div>Loading...</div>;
	}
};

export default TableComponent;
