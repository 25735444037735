import styles from "./home.component.module.scss"
import CourseListComponent from "./components/course-list/course-list.component";
import { useState } from "react";
import CourseWeeklyOverviewComponent from "../tabs/overview/components/course-weekly-overview";
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const HomeComponent = () => {

  const [drillDownIndex, setDrillDwnIndex] = useState<number>(0);
  const [selectedCourseName, setSelectedCourseName] = useState<string>("")


  const increaseDrillDownIndex = (courseName: string) => {
    setDrillDwnIndex(drillDownIndex + 1)
    setSelectedCourseName(courseName);
  }

  const decreaseDrillDownIndex = () => {
    if (drillDownIndex !== 0) {
      setDrillDwnIndex(drillDownIndex - 1)
      setSelectedCourseName("");
    }
  }


  //TODO 2 Home > Course > Week and in week component pagination < 25 26 27 28 ... 30 > => in version 1.2.0
  //TODO 2 make own common component => component needed when multiple uses
  const renderDrillDownComponent = (index: number) => {
    switch (index) {
      case 0:
        return <CourseListComponent increaseDrillDownIndex={increaseDrillDownIndex} />
      case 1:
        return <CourseWeeklyOverviewComponent courseType={selectedCourseName} />


      default:
        break;
    }
  }

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Typography style={{ cursor: "pointer" }} onClick={() => { decreaseDrillDownIndex() }}>Übersicht</Typography>
        <Typography style={{ cursor: "pointer" }}>{selectedCourseName}</Typography>
      </Breadcrumbs>
      <div className={styles.innerWrapper}>
        {renderDrillDownComponent(drillDownIndex)}
      </div>
    </>
  )
}

export default HomeComponent;