import { Outlet, useNavigate, useLocation, Navigate } from "react-router-dom"
import styles from "./side-bar.component.module.scss"
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import GridOnOutlinedIcon from '@mui/icons-material/GridOnOutlined';
import { Typography } from "@mui/material";
import { NavigationItemModel } from "./models/navagation-item.model";
import { useEffect, useState } from "react";
import { AuthServices, IAuthServices } from "../../../services/AuthServices";


const navigationItems: NavigationItemModel[] = [
  {
    name: "Übersicht",
    path: "/home",
    icon: <img alt="dashboardIcon" src="/navbarIcons/dashboard.png" height={25} />,
  },
  {
    name: "Tabelle",
    path: "/table",
    icon: <img alt="tableIcon" src="/navbarIcons/table.png" height={25} />,
  },
  {
    name: "Dateien",
    path: "/upload",
    icon: <img alt="dashboardFolder" src="/navbarIcons/folder.png" height={25} />,
  },
]

const SideBarComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const authServices: IAuthServices = new AuthServices();

  const [currentPath, setCurrentPath] = useState<string>(location.pathname);

  useEffect(() => {
    setCurrentPath(location.pathname);
  })

  const navigateToPath = (path: string) => {
    setCurrentPath(path)
    navigate(path, { replace: true });
  }

  const signOut = () => {
    authServices.signOut();
    navigate("/signIn", { replace: true });
  }

  return (
    <>
      <div className={styles.sideBarWrapper}>
        <div className={styles.logoWrapper} style={{}}>
          <img alt="aquafitLogo" src="Logo-AquaFit-vec.svg" />
        </div>
        <div className={styles.navigationWrapper}>
          {navigationItems.map((navigationItem, index) => {
            return (
              <div key={index} onClick={() => { navigateToPath(navigationItem.path) }} className={styles.navigationItem} style={{ color: currentPath === navigationItem.path ? "#0b3a8a" : "black" }}>
                {navigationItem.icon}
                <Typography className={styles.navigationText}>{navigationItem.name}</Typography>
                {currentPath === navigationItem.path && <div className={styles.rightElement}></div>}
              </div>
            )
          })}
          <div className={styles.bottomItems}>
            <div onClick={signOut} className={styles.navigationItem}>
              <img alt="logoutIcon" src="/navbarIcons/logout.png" height={25} />
              <Typography className={styles.navigationText}>Logout</Typography>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginLeft: 250, padding: 24, height: "100%", overflowY: "scroll", backgroundColor: "#00000009" }}>
        <Outlet />
      </div>
    </>
  )
}

export default SideBarComponent;